/* Bootstrap */ 
@import "_card";

/* index */

#welcome p{
    font-size: 20px;
}

.card {
  margin-left: 0.75em;
  margin-right: 0.75em;
  margin-bottom: 1.75em;
}
.card img {
    margin: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.card-holder {
  grid-template-columns: auto auto;
  display: grid;
}
.card-footer {
    background-color: revert;
    border-top: none;
}

@media (min-width: 80em) {
  .card-holder {
      margin-left: 2em;
      margin-right: 2em;
      }
}

/* Projects */

.project {
  max-width: unset !important;
}

.mobile-only {
  display: unset;
}

@media (min-width: 48em) {
  .mobile-only {
    display: none;
  }
}

@media (min-width: 48em) {
  .project {
    margin-left: 20rem !important;
    margin-right: 2rem !important;
  }
}

/*songspace*/

.red-text {
  color: red;
}

#songspace-chart-div {
  width: 100%;
  height: 750px;
  border: 1px solid black;
}

.spotify-button {
  background-color: #1DB954;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.5em 2em 0.5em 2em;
  border-radius: 20px;
}

.spotify-button-div {
  text-align: center;
}

#songspace-search-div {
  text-align: right;
}

/*social-button and social-buttons seem to be set to 'display: none' by adblock, so use a different name*/

#custom-social-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-right: 2.5rem;
}
@media (max-width: 48em) {
  #custom-social-buttons {
    margin-right: 17rem;
    margin-left: 0rem;
  }
}
@media (max-width: 34em) {
  #custom-social-buttons {
    margin-right: 12rem;
    margin-left: 0rem;
  }
}

.sidebar img {
  margin: 0;
}

.custom-social-link {
  width: 25%;
  display: inline;
  position: relative;
  transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -webkit-transition: opacity 0.35s ease-in-out;
}

.custom-social-button:hover {
  opacity: 0.9;
  transition: inherit;
  /*transform: scale(1.04);*/
}

.custom-social-button {
  opacity: 0.6;
  transition: inherit;
}

.lead small {
  font-size: 0.75rem;
}

p {
  font-size: 14px;
}

// Code boxes are too left
figure {
    margin-inline-start: 1em;
}
figure .highlight {
    margin-inline-start: auto;
}


// Thanks https://aimuke.github.io/guides/2019-06-01-line-numbers/
// so that the line numbers are not selectable
@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// having a lineno-container made it easier to style line number
// code blocks without impacting normal code blocks
.lineno-container > figure > pre {
  padding: 0px;
}

.highlight {
  pre.lineno {
    border: none;
    @include opacity(0.6);
  }

  .lineno {
    @include unselectable();
  }

  .gutter {
    border-right: 1px solid #ccc;
  }

  pre {
    pre {
      border: none;
      margin-bottom: 0px;
    }
  }
}
